/* .primary.rmdp-wrapper {
	border: 1px solid var(--color-primary-300);
} */

.primary.rmdp-shadow {
	box-shadow: 0px 5px 12px 0px #0000001a, 0px -5px 12px 0px #0000001a;
}

.primary.rmdp-ep-shadow:after {
	box-shadow: none;
}

.primary .rmdp-week-day {
	color: var(--color-primary-300);
}

.primary .rmdp-day.rmdp-selected span:not(.highlight) {
	background-color: var(--color-primary-300);
	box-shadow: none;
}

.primary .rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
	border: 1px solid var(--color-primary-300);
	background-color: #fff;
	color: #000;
}

.primary .rmdp-day.rmdp-today span {
	background-color: var(--color-primary-200);
}

.primary .rmdp-day.rmdp-deactive,
.primary .rmdp-day.rmdp-disabled {
	color: var(--color-secondary-300);
}

.primary .rmdp-arrow-container:hover {
	background-color: var(--color-primary-300);
	box-shadow: none;
}

.primary .rmdp-arrow {
	border-color: var(--color-primary-300);
	margin-top: 7px;
}

.primary .rmdp-arrow-container.disabled:hover {
	background-color: transparent;
}

.primary .rmdp-arrow-container.disabled .rmdp-arrow,
.primary .rmdp-arrow-container.disabled:hover .rmdp-arrow {
	border-color: var(--color-secondary-300);
}
